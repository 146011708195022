.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-link {
    display: flex;
    align-items: center;
  }
}

.nav-line-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EFF2F5;

  .nav-item {
    margin-bottom: -1px;

    .nav-link {
      color: #6c757d;
      border: 0;
      border-bottom: 1px solid transparent;
      transition: color 0.2s ease, background-color 0.2s ease;
      padding: 0.5rem 0;
      margin: 0 1rem;
    }

    &:first-child .nav-link {
      margin-left: 0;
    }

    &:last-child .nav-link {
      margin-right: 0;
    }

    .nav-link.active, &.show .nav-link, .nav-link:hover:not(.disabled) {
      background-color: transparent;
      color: #138496;
      border: 0;
      border-bottom: 1px solid #138496;
      transition: color 0.2s ease, background-color 0.2s ease;
    }
  }

  &.nav-line-tabs-2x {
    border-bottom-width: 2px;

    .nav-item {
      margin-bottom: -2px;

      .nav-link {
        border-bottom-width: 2px;

        &.active {
          border-bottom-width: 2px;
        }
      }

      &.show .nav-link, .nav-link:hover:not(.disabled) {
        border-bottom-width: 2px;
      }
    }
  }
}

.nav-underline{
    .nav-item {

    }
    .nav-link {
        color: #212529;
        font-weight: 600;
        padding: .75rem 1.5rem;
        font-size: .875rem;
        position: relative;
        &.active {
            color: #138496;
            //border-bottom: 2px solid #138496;
            background: #f8f9fa;
            &:after {
                left: 0;
                right: 0;
            }
        }

        &:hover:not(.active) {
            //border-bottom: 2px solid #138496;
        }
        &:after {
            content: '';
            background: #138496;
            position: absolute;
            transition: all 0.3s ease-in-out;
            left: 50%;
            right: 50%;
            bottom: 0;
            height: 2px;
            border-radius: 2px 2px 0 0;
        }
        &.link-success.active {
            color: $success;
            background: #e6f9ea;
            &:after {
                background: $success;
            }

        }
        &.link-danger {
            color: $danger;
            &.active {
                color: $danger;
                background: #fbeaec;
                &:after {
                    background: $danger;
                }

            }
        }

    }
}

.navbar-dark.top-menu {
    padding: .75rem 1rem;
    .navbar-nav {
        .nav-link {
            font-size: .875rem;
            padding: .5rem 1rem;
            color: rgba(255,255, 255, .75);

            &:hover {
                background-color: #31374a;
                border-radius: 5px;
                color: rgb(255,255, 255);
            }

            &.nav-avatar {
                padding: 0 1rem;
                &:hover {
                    background-color: transparent;
                }
            }
        }

        .dropdown-item {
            font-size: .875rem;
        }
    }

    .navbar-nav-icons {
        .dropdown-menu {
            position: absolute;
        }
    }

    .navbar-toggler {
        border: 0;
        color: rgba(255,255, 255, .75);
    }

}

//@include media-breakpoint-up(xs)	@media (min-width: 0px)
//@include media-breakpoint-up(sm)	@media (min-width: 576px)
//@include media-breakpoint-up(md)	@media (min-width: 768px)
//@include media-breakpoint-up(lg)	@media (min-width: 992px)
//@include media-breakpoint-up(xl)	@media (min-width: 1200px)

//@include media-breakpoint-down(xs)	@media (max-width: 575.98px)
//@include media-breakpoint-down(sm)	@media (max-width: 767.98px)
//@include media-breakpoint-down(md)	@media (max-width: 991.98px)
//@include media-breakpoint-down(lg)	@media (max-width: 1199.98px)
//@include media-breakpoint-down(xl)	N/A

//@include media-breakpoint-only(xs)	@media (max-width: 575.98px)
//@include media-breakpoint-only(sm)	@media (min-width: 576px) and (max-width: 767.98px)
//@include media-breakpoint-only(md)	@media (min-width: 768px) and (max-width: 991.98px)
//@include media-breakpoint-only(lg)	@media (min-width: 992px) and (max-width: 1199.98px)
//@include media-breakpoint-only(xl)	@media (min-width: 1200px)

//@include media-breakpoint-between(xs, $size)	Just use @include media-breakpoint-down($size)
//@include media-breakpoint-between($size, xl)	Just use @include media-breakpoint-up($size)
//@include media-breakpoint-between(sm, md)	@media (min-width: 576px) and (max-width: 991.98px)
//@include media-breakpoint-between(sm, lg)	@media (min-width: 576px) and (max-width: 1199.98px)
//@include media-breakpoint-between(md, lg)	@media (min-width: 768px) and (max-width: 1199.98px)

@include media-breakpoint-down(sm) {
    .navbar-dark.top-menu {
        padding: .5rem .25rem;
    }
}

@include media-breakpoint-down(xl) {
    .nav-underline {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        padding: 0;

        .nav-item {
            flex: 0 0 auto;
        }
    }

}
