.report-page {

    h2 {
        font-weight: 700;
        font-size: 1.25rem;
    }

    h3 {
        font-weight: 700;
        font-size: 1rem;
    }

}

@media (max-width: 767px) {
    .chartHeight {
        min-height: 240px !important;
    }

}
