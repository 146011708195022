.card {
    .card-custom {
        box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
        border: 0;
    }
}

.bg-danger {
    background-color: #F64E60 !important;
}

.text-inverse-danger {
    color: #ffffff !important;
}
.text-inverse-danger-subtitle {
    color: #fbadb5 !important;
}

.font-size-h3 {
    font-size: 1.5rem !important;
}

.font-size-sm {
    font-size: 0.75rem;
}


.card-spacer-x {
    padding-left: 1rem !important;
    padding-right: 0.25rem !important;
}

.separator {
    &.separator-white {
        border-bottom-color: #ffffff;
    }

    &.separator-solid {
        border-bottom: 1px solid #EBEDF3;
    }
}

.opacity-20 {
    opacity: 0.2 !important;
}
.overflow-x-auto {
    overflow-x: auto;
}

@media (max-width: 1024px) {

    .width-100 {
        width: 50vw;
    }

}


@media (max-width: 767px) {

    .width-100 {
        width: 100vw;
    }



}
