
// Fonts
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&subset=latin-ext");
//@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

@import '~bootstrap';

// Variables
@import "variables";

@import "_font";
@import "_stats";
@import "_custom";
@import "_navtab";
@import "_report";
@import "_borders_extra";
@import "flag-icons";
