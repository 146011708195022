
body {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.02rem;
}

$btn-light-success-bg: #e6f9ea;
$btn-light-success-border: #28a745;

.btn-light-success {
    @include button-variant($btn-light-success-bg, $btn-light-success-border, darken($btn-light-success-bg, 7.5%), $btn-light-success-border);

    // optitional
    color: $btn-light-success-border;
    &:hover {
        color: $btn-light-success-border;
    }
    &.disabled,
    &:disabled {
        color: lighten($btn-light-success-border, 5%);
    }
}

$btn-light-danger-bg: #fbeaec;
$btn-light-danger-border: #dc3545;

.btn-light-danger {
    @include button-variant($btn-light-danger-bg, $btn-light-danger-border, darken($btn-light-danger-bg, 7.5%), $btn-light-danger-border);

    // optitional
    color: $btn-light-danger-border;
    &:hover {
        color: $btn-light-danger-border;
    }
    &.disabled,
    &:disabled {
        color: lighten($btn-light-danger-border, 5%);
    }
}

$btn-light-info-bg: #e4f8fb;
$btn-light-info-border: #17a2b8;

.btn-light-info {
    @include button-variant($btn-light-info-bg, $btn-light-info-border, darken($btn-light-info-bg, 7.5%), $btn-light-info-border);

    // optitional
    color: $btn-light-info-border;
    &:hover {
        color: $btn-light-info-border;
    }
    &.disabled,
    &:disabled {
        color: lighten($btn-light-info-border, 5%);
    }
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: $btn-light-success-border;
    outline: 0;
    box-shadow: none;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.notie-container {
    z-index: 1051 !important;
}

// Animation

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bg-dark {
background-color: #161d33 !important;
}

.bg-gray {
    background-color: #f9f9f9;
}

.table tbody+tbody {
  border-top: 0;
}

.table {

    .thead-transparent {
        th {
            background-color: transparent !important;
            color: #333;
            font-size: 0.75rem;
            font-weight: 600;
            border: 1px solid #efefef;
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
        }
    }
}

.table {

  .thead-dark {
    th {
      background-color: #1c233f !important;
      color: #e6e1dc;
      font-size: 0.75rem;
      font-weight: 600;
      border: 1px solid #1c233f;
    }
  }
  .thead-dark2 {
    th {
      background-color: rgba(28, 35, 63, 0.1);
      font-size: 0.875rem;
      font-weight: 600;
      border: 1px solid #efefef;
    }
  }
}

.table-bordered th, .table-bordered td {
  border: 1px solid #efefef;
}


.table-bordered thead th {
  border: 1px solid #1c233f;
}

.font-weight-sbold {
  font-weight: 600 !important;
}

p {
  font-size: 0.8125rem;
}



.modal-link {
  font-size: 0.8125rem;
  display: block;
  color: #161d33;
  text-decoration: underline;
}

.btn-group-sm>.btn,
.btn-sm {
  font-size: 0.8125rem;
}

.badge {
  padding: 0.5em 0.8em;
  font-weight: 600;
}

.badge-status {
  padding: 0.25em 0.5em;
  font-size: 11px;
  letter-spacing: 0.05rem;
  font-weight: 400;

  &.yeni {
    color: #fff;
    background-color: #17a2b8;
  }

  &.isleniyor {
    color: #212529;
    background-color: #ffc107;
  }

  &.kargolanan {
    color: #fff;
    background-color: lighten(#28a745,10);
  }

  &.tamamlanan {
    color: #fff;
    background-color: darken(#28a745, 10);
  }

  &.iptal {
    color: #fff;
    background-color: #dc3545;
  }

  &.arsiv {
    color: #fff;
    background-color: #6c757d;
  }
}


.table-striped2 tbody:nth-of-type(odd) tr {
  background-color: rgba(0,0,0,.03);
}

.table-striped2 tr.tRow.tRowActive, .trStockRow {
  background-color: rgba(28, 35, 63, 0.1) !important;

}

.table-striped2 tbody .tRow.iptal,
.table-striped2 tbody .tRow .iptal {
  background-color: rgba(220, 53, 69,0.07) !important;
}

.table-striped2 tbody .tRow.checked,
.table-striped2 tbody .tRow .checked {
  background-color: rgba(255, 238, 186,.3)!important
}

.table-striped2 tbody .tRow.tamam,
.table-striped2 tbody .tRow .tamam {
  background-color: rgba(40,167,68,.1)!important;
}

.table-striped2 tbody .tRow.info,
.table-striped2 tbody .tRow .info {
    background-color: rgb(186,227, 255, .3)!important;
    td{
        border: 1px solid #ffffff;
    }
}

.table-striped tbody .tRow .iptal {
  background-color: rgba(220, 53, 69,0.07) !important;
}


.table-striped2 tr.tRowActive td.tdGuncel {
  text-align: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #161d33;
    bottom: -1px;
    left: calc(50% - 16px);
  }

}

td.tdGuncel {
  position: relative;
}



.trStockRow {
  background-color: #161d33 !important;
  color: #e6e1dc;
  -webkit-animation: fadeIn 500ms;
  animation: fadeIn 500ms;
  -webkit-backface-visibility: hidden;
}

.numaraCheck {
  background: #31c971 !important;
  color: white !important;
}

.numaraOut {
  background: red !important;
  border: 1px solid black !important;
  color: white !important;
  font-weight: bold;
  font-size:15px !important;
}



.w150 {
  max-width: 150px;
}

.w175 {
  min-width: 175px;
}

.wm200 {
    min-width: 200px;
}

.wm225 {
    min-width: 225px;
}

.wm265 {
  min-width: 265px;
}
.faturaTd {
  word-break: break-word;
  max-width: 350px;
}

.seperator {
  border-bottom: 1px solid #efefef;
  padding-bottom: 7px;
  margin-bottom: .5rem!important;
}

.seperator:last-child {
  border: none !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px!important;
}

.saat {
  color:#d0021b;
  font-size: 0.75rem;
}

.text-danger {
  color:#d0021b !important;

}

.log-hareket {
  font-size: 0.75rem;
  font-weight: 600;
}

.svg-flo {
  height: 14px;
  width: auto;
}

.ups {
  width: 30px;
}

.mng, .aras, .dhl, .pts, .sendeo, .trendyolexpress {
  width: 75px;
}

.telegramLog {
  background: #1c233f;
}

.telegramList, .stockList, .saleList, .saleListStore, .stokTransferList, .recentSaleList {
    color: #b9c0d3;
    display: block;
    padding: 1rem;

    .hljs {
        color: #e6e1dc;
    }

    .hljs-success {
        color: #a5c261;
    }

    .hljs-danger {
        color: #dc3545;
    }
}

.siparisNoteList {
    display: block;
    background: #ffc107;
    color: #161d33;
    padding: 1rem;
    margin-top: 3rem;

    p {
        margin: 0;
    }
}

.messageList {
  display: block;
  background: #e83e8c;
  color: #161d33;
  padding: 1rem;
  margin-top: 3rem;

  p {
    margin: 0;
  }
}

.dot {
  background: #e83e8c ;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-flex;
}
.dot-white {
    background: #fff ;

}
.badge-message {
  background: #e83e8c ;
  color: #161d33;

}

//

.stokTable {
  h4 {
    font-size: 0.8125rem;
    color: #b9c0d3;
  }
}

.tableStock {
  color: #b9c0d3;
  background-color: #1c233f;
  font-size: 0.8125rem;

  tr {


    td {
      border: 2px solid rgba(22, 29, 51,1);

    }

    &:hover {
      background-color: rgba(0,0,0,.075) !important;
    }
  }

  .thHeader {
    background-color: rgba(156, 39, 176,0.4);
    color: rgba(255,255,255,0.8);
  }

  .thHeaderRed {
    background-color: rgba(245, 67, 148,0.4);
    color: rgba(255,255,255,0.8);
  }

  .numaraHeader {
    font-weight: 600;
    text-align: center;
    border: 2px solid rgba(22, 29, 51,1);
    background-color: rgba(156, 39, 176,0.2);
    color: #fff;

  }

  .magazaTd {
    text-align: left;
  }

  .numaraTd {
    text-align: center;
    font-weight: 600;

  }

  .numaraTd, .magazaTd {
    border: 2px solid rgba(22, 29, 51,1);
    padding: 0.5rem;
    font-size: 0.875rem;
  }

}

.panellinks {
  .nav-pills {
    .nav-item {
      background-color: #161d33 ;

      .nav-link {
        border-radius: 0;

        padding: 1.5em;
        text-transform: uppercase;
        font-size: 0.875rem;
        letter-spacing: 0.1rem;
        font-weight: 400;
        color: #e6e1dc;
        border-right: 1px solid #1c233f;
        min-width: 180px;
        text-align: center;

        &.active {
          background-color: rgba(156, 39, 176, .4);
          color: #fff;
        }

        span {
          display: block;
          font-size: 0.8175rem;
          color:rgba(230, 225, 220, 0.5);
        }

        &.bg-red {
          background-color: rgba(176, 39, 39, 0.6)
        }

        &.bg-green {
          background-color: rgba(30, 126, 52, .3)
        }
      }
    }


  }
}

//ScrollBar
.scrollbar-hide {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.scrollbar-hide::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}


// Modal

.modal-content {
  border-radius: 0;
  border: 0;

  .close {
    text-shadow : none;
    opacity: 1;
    color: #fff;
  }
}

.modal-header {
  border-radius: 0;
  border-bottom : 0;
  background: #1c233f;
  color: #fff;
}

.modal-body {
  font-size: 14px;


}

// empty-tab

.empty-tab {
    border-bottom: 0;

    .nav-link.active,
    .nav-item.show .nav-link {
        background-color: rgba(0,0,0,0);
        border: none;
        color: #ff7000;

    }

    .nav-link {
        font-weight: 600;
        font-size: .75rem;
        background-color: rgba(0,0,0,0);
        margin-bottom: 0;
        border: none;
        padding: 0.25rem 0.5rem;
        text-decoration: underline;
        color: #495057;
    }
}


//Copy to text css

.copy-to-clipboard {
    cursor: pointer;
    svg {
        width: 1em;
        height: 1em;
        vertical-align: -0.125em;
        fill: $body-color;
    }
    &:hover{
        svg {
            fill: $text-muted;
        }
    }
}

//Pagination
.page-item {
    margin: .125rem;
}
.page-link {
    border: 0;
    border-radius: .375rem;
    font-size: .8rem;
    line-height: 1.2;
}

//Content Placeholder

$ph-width: 10vw;
$ph-border-color: #e5e5e5;
$ph-border-radius: .25em;
$ph-bg-wrapper: #f8f8f8;
$ph-bg-default: #e5e5e5;
$ph-bg-animate: #d5d5d5;


@keyframes ph-gradiant {
    0%{
        background-position: 100% 0
    }
    100%{
        background-position: -100% 0
    }
}

.ph-animated {
    position: relative;
    background: linear-gradient(to right, $ph-bg-default, $ph-bg-animate 20%, $ph-bg-default 70%);
    background-size: 200% 100%;
    animation: ph-gradiant 1.5s linear infinite;
}

.ph-wrapper {
    width: $ph-width;
    background: $ph-bg-wrapper;
    border: 1px solid $ph-border-color;
    border-radius: $ph-border-radius;
}

.ph-image,
.ph-text-title,
.ph-text,
.ph-text-short {
    height: .7rem;
    max-width: 100%;
    margin: 0.5rem;
    margin-bottom: 1.5rem;
    border-radius: $ph-border-radius;
}

.ph-image {
    height: 3rem;
    margin-bottom: 2.25rem;
}

.short {
    max-width: 60%;
}

.shorter {
    max-width: 40%;
}

@media (max-width: 1024px) {
  .panellinks {
    .nav-pills {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      padding: 0;

      .nav-item {
        flex: 0 0 auto;
      }
    }
  }

}
